<template>
  <div>
    <div class="container">
      <!-- 導覽列 -->
      <nav aria-label="breadcrumb" class="pt-2 pt-md-4 pb-0 pb-md-3 w-50 mb-2">
        <div class="animated-background py-2"></div>
      </nav>
      <!-- 產品內容 -->
      <div class="d-lg-flex justify-content-between border-bottom border-secondary border-1 pb-4 pb-md-6 mb-4 mb-md-5">
        <!-- 產品圖片組 -->
        <div class="images">
          <!-- 產品圖片組 - PC -->
          <div class="d-none d-lg-block">
            <div class="position-relative mb-2">
              <div class="animated-background ratio ratio-1x1"></div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <div class="ratio ratio-1x1 animated-background"></div>
              </div>
              <div class="col-lg-3">
                <div class="ratio ratio-1x1 animated-background"></div>
              </div>
              <div class="col-lg-3">
                <div class="ratio ratio-1x1 animated-background"></div>
              </div>
              <div class="col-lg-3">
                <div class="ratio ratio-1x1 animated-background"></div>
              </div>
            </div>
          </div>
          <!-- 產品圖片組 - mobile -->
          <div class="d-lg-none mb-4 mb-sm-5 pb-4">
            <div class="animated-background ratio ratio-1x1"></div>
          </div>
        </div>
        <!-- 產品詳細資訊 -->
        <div class="details d-flex flex-column justify-content-between mt-3 mt-md-0">
          <div>
            <h1 class="animated-background py-4 fs-2 mb-4"></h1>
            <div class="animated-background py-3 mb-4"></div>
            <div class="animated-background ratio ratio-16x9 mb-4 mb-md-6"></div>
          </div>
          <div>
            <!-- 活動說明  -->
            <div class="border-top border-1 border-secondary pt-4 mb-3">
              <div class="d-flex justify-content-between align-items-center"></div>
            </div>
            <div class="row g-3">
              <div class="col-6">
                <!-- 數量 -->
                <div class="productNumber input-group">
                  <button
                    type="button"
                    class="btn border-dark rounded-0"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    class="form-control border-dark text-center fs-6 fs-lg-5 py-2"
                    aria-label="product number"
                    id="num"
                    min="1"
                    readonly
                  />
                  <button type="button" class="btn border-dark rounded-0">
                    +
                  </button>
                </div>
              </div>
              <div class="col-6">
                <!-- 加入購物車 -->
                <div ref="addCartBtn" class="d-grid gap-2">
                  <button class="btn btn-primary rounded-0 fs-6 fs-lg-5 py-2" type="button">加入購物車</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 商品描述 / 商品規格 -->
      <ul class="productDescription nav nav-pills text-start text-md-center mb-3 mb-md-4" id="pills-tab" role="tablist">
        <li class="nav-item d-inline-block" role="presentation">
          <button class="nav-link rounded-0 fw-medium text-dark fs-3 px-0 mx-md-3" id="pills-home-tab" type="button">商品描述</button>
        </li>
        <li class="nav-item d-inline-block" role="presentation">
          <button class="nav-link rounded-0 fw-medium text-dark fs-3 px-0 ms-4 mx-md-3" id="pills-profile-tab" type="button">商品規格</button>
        </li>
      </ul>
      <div class="animated-background productDescriptionContent tab-content ratio ratio-1x1 mx-auto mb-6" id="pills-tabContent"></div>
      <!-- 相關推薦 -->
      <h2 class="text-center mb-5">
        <span class="fs-3 fw-medium border-bottom border-primary border-2 d-inlink-block pb-2"
          >相關推薦</span
        >
      </h2>
      <div class="row gy-3">
        <div class="col-3">
          <div class="card border-0">
            <div class="ratio ratio-1x1 animated-background"></div>
            <div class="card-body px-0 pt-2 animated-background">
              <h5 class="animated-background card-title card-title-height animated-background"></h5>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card border-0">
            <div class="ratio ratio-1x1 animated-background"></div>
            <div class="card-body px-0 pt-2 animated-background">
              <h5 class="animated-background card-title card-title-height animated-background"></h5>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card border-0">
            <div class="ratio ratio-1x1 animated-background"></div>
            <div class="card-body px-0 pt-2 animated-background">
              <h5 class="animated-background card-title card-title-height animated-background"></h5>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card border-0">
            <div class="ratio ratio-1x1 animated-background"></div>
            <div class="card-body px-0 pt-2 animated-background">
              <h5 class="animated-background card-title card-title-height animated-background"></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/pages/_product';
</style>
